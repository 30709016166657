import React, { useState } from 'react';
import { ethers, BigNumber } from 'ethers';
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import Cybermurai from './Cybermurai.json';

const cybermuraiAddress = "0x41961FbAc66Ab5d4a1165Db454377a701a1c9fC3";

const MainMint = ({ accounts, setAccounts }) => {
    const [mintAmount, setMintAmount] = useState(1);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const isConnected = Boolean(accounts[0]);

    const handleMint = async () => {
        if (!window.ethereum) {
            setError("Web3 provider not found. Make sure you have a web3 enabled browser.");
            return;
        }

        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                cybermuraiAddress,
                Cybermurai.abi,
                signer
            );

            const response = await contract.mint(BigNumber.from(mintAmount));
            console.log('Mint transaction successful: ', response);
            setSuccess("Mint transaction successful");
            setError(null);
        } catch (err) {
            console.error("Mint transaction error: ", err);
            setError("Mint transaction error. Please try again.");
            setSuccess(null);
        }
    };

    const handleDecrement = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    };

    const handleIncrement = () => {
        if (mintAmount >= 5) return;
        setMintAmount(mintAmount + 1);
    };

    return (
<Flex justify="center" align="center" paddingBottom="0px">
  <Box width="520px" overflowY="scroll" height="100%">
    <div>
      <Text fontSize="48px" textShadow="0 5px #000000">Cybermurai</Text>
      <Text
        fontSize="30px"
        letterSpacing="-5.5%"
        fontFamily="VT323"
        textShadow="0 2px 2px #000000"
      >
        It's 2069. Can the Cyber Samurai's save humanity from destructive NFT speculation? Mint Cybermurai to find out.
      </Text>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
    </div>

    {isConnected ? (
      <div>
        <Flex align="center" justify="center">
          <Button
            backgroundColor="#D6517D"
            borderRadius="5px"
            boxShadow="0px 2px 2px 1px #0F0F0F"
            color="white"
            cursor="pointer"
            fontFamily="inherit"
            padding="15px"
            marginTop="10px"
            onClick={handleDecrement}
          >
            -
          </Button>
          <Input
            readOnly
            fontFamily="inherit"
            width="100px"
            height="40px"
            textAlign="center"
            paddingLeft="19px"
            marginTop="10px"
            type="number"
            value={mintAmount} 
          />
          <Button
            backgroundColor="#D6517D"
            borderRadius="5px"
            boxShadow="0px 2px 2px 1px #0F0F0F"
            color="white"
            cursor="pointer"
            fontFamily="inherit"
            padding="15px"
            marginTop="10px"
            onClick={handleIncrement}
          >
            +
          </Button>
        </Flex>
        <Button
          backgroundColor="#D6517D"
          borderRadius="5px"
          boxShadow="0px 2px 2px 1px #0F0F0F"
          color="white"
          cursor="pointer"
          fontFamily="inherit"
          padding="15px"
          marginTop="10px"
          onClick={handleMint}
        >
          Mint Now
        </Button>
      </div>
    ) : (
      <Text
        marginTop="70px"
        fontSize="30px"
        letterSpacing="-5.5%"
        fontFamily="VT323"
        textShadow="0 3px #000000"
        color="#D6517D"
      >
        You must connect to Mint.
      </Text>
    )}
      <div>
      <br />
      <br />
      <br />
      <br />
      <br />
        <Text fontSize="38px" textShadow="0 5px #000000">FAQS</Text>
        <Text
            fontSize="33px"
            letterSpacing="-5.5%"
            fontFamily="VT323"
            textShadow="0 2px 2px #000000"
         >
            What is Cybermurai?
        </Text>
        <Text
            marginTop="-20px"
            fontSize="25px"
            letterSpacing="-5.5%"
            fontFamily="VT323"
            textShadow="0 2px 2px #000000"
        >
            Cybermurai's live on the Ethereum blockchain where their existence and abilities are encoded in smart contracts.
        </Text>
        <Text
            fontSize="33px"
            letterSpacing="-5.5%"
            fontFamily="VT323"
            textShadow="0 2px 2px #000000"
        >
            What is mint price?
        </Text>
        <Text
            marginTop="-20px"
            fontSize="25px"
            letterSpacing="-5.5%"
            fontFamily="VT323"
            textShadow="0 2px 2px #000000"
        >
            Whitelist: 0.0069 ETH
            <br />
            Public: 0.069 ETH
        </Text>
        <Text
            fontSize="33px"
            letterSpacing="-5.5%"
            fontFamily="VT323"
            textShadow="0 2px 2px #000000"
        >
            When is mint date?
            </Text>
            <Text
                marginTop="-20px"
                fontSize="25px"
                letterSpacing="-5.5%"
                fontFamily="VT323"
                textShadow="0 2px 2px #000000"
             >
                Mint will begin on 25-02-2023
            </Text>
            <Text
                fontSize="33px"
                letterSpacing="-5.5%"
                fontFamily="VT323"
                textShadow="0 2px 2px #000000"
            >
                What is the supply?
            </Text>
            <Text
                marginTop="-20px"
                fontSize="25px"
                letterSpacing="-5.5%"
                fontFamily="VT323"
                textShadow="0 2px 2px #000000"
            >
                Cybermurai will have a supply of 333 NFTS
            </Text>
        </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
        <footer>
    <p>&copy; 2023 Cybermurai. All rights reserved.</p>
</footer>
    </Box>
</Flex>
    );
};

export default MainMint;
